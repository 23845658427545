import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css';
import {Carousel,Icon,Button,Row,BackTop,Anchor,Drawer} from "ant-design-vue";

Vue.config.productionTip = false
Vue.use(Carousel)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Row)
Vue.use(BackTop)
Vue.use(Anchor)
Vue.use(Drawer)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
