import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import('../views/Index')
    },
    {
        path: '/index',
        name: 'Index',
        component: () => import('../views/Index')
    },
    {
        path: '/home',
        name: 'Index',
        component: () => import('../views/Index')
    },
]

const router = new VueRouter({
    routes,
    base:process.env.BASE_URL
    // mode:'history'
})

export default router
